import http from "../util/http.js";
import http2 from "../util/http2.js";

// 登录
export const login = (type,username,password,code) => {
  return http
    .post("/site/do-login", {
      username: username,
      password: password,
      type: type,
      smsCode: code,
    })
    .then((res) => res.data);
};

export const regmobile = (username,type,companyname) => {
  return http
    .post("/site/check-username", {
      username: username,
      type: type,
      companyname:companyname
    })
    .then((res) => res.data);
};


export const sendsms = (username) => {
  return http
    .post("/site/send-message", {
      mobile: username,
      type: 2,
    })
    .then((res) => res.data);
};


export const forgetmobile = (username) => {
  return http
    .post("/site/password-forget", {
      username: username,
    })
    .then((res) => res.data);
};

export const passwordchange = (username,code,password) => {
  return http
    .post("/site/password-change", {
      username: username,
      code:code,
      password:password
    })
    .then((res) => res.data);
};


export const getprovincelist = () => {
  return http
    .get("/site/get-province-list", {
    })
    .then((res) => res.data);
};

export const getcitylist = (province) => {
  return http
    .get("/site/get-city-list", {
      params:{
        provinceid:province
      }
    })
    .then((res) => res.data);
};

export const getcountylist = (city) => {
  return http
    .get("/site/get-county-list", {
      params:{
        cityid:city
      }
    })
    .then((res) => res.data);
};


export const getCodeUrl = () => {
  return http2
    .get("/site/get-code-url3", {
    })
    .then((res) => res.data);
};

export const getState = (state) => {
  return http2
    .get("/site/get-state", {
      params:{
        state:state
      }
    })
    .then((res) => res.data);
};





